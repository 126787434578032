



<template>

    <Section :options="computedOptions.sectionOptions">

         <div v-if="labels.title" class="heading-block center">
            <h2>{{ $cms.utils.getComputedLabel(labels.title) }}</h2>
        </div>

        <div class="row align-items-stretch team-full-row">

            <div :class="pictureCssClass">
                <div>
                    <img v-if="computedOptions.picture && computedOptions.hoverPicture" :src="computedOptions.hoverPicture" :alt="$cms.utils.getComputedLabel(labels.pictureAlt)">
                    <img v-if="computedOptions.picture" :src="computedOptions.picture" :alt="$cms.utils.getComputedLabel(labels.pictureAlt)">
                </div>
            </div>

            <div class="col-lg-8 p-4 ps-5 pe-5 team-description">
                <div class="vertical-middle">
                    <div class="heading-block mb-4">
                        <span v-if="labels.position" class="before-heading color">{{ $cms.utils.getComputedLabel(labels.position) }}</span>
                        <h3>{{ $cms.utils.getComputedLabel(labels.name) }}</h3>
                    </div>

                    <div class="row col-mb-50">

                        <div class="col-lg-12">
                            <LocalizedContent v-if="labels.text" class="team-content" :localizedContent="labels.text"></LocalizedContent>
                            <SocialLinks v-if="computedOptions.socialMedias" :options="{iconClass:'inline-block si-large si-light si-rounded', socialMedias: computedOptions.socialMedias}" />
                        </div>

                    </div>

                </div>
                <img v-if="computedOptions.signature" :src="computedOptions.signature" :alt="$cms.utils.getComputedLabel(labels.signatureAlt)" class="signature">
            </div>

        </div>

    </Section>
    
</template>

<style>

    .team-full-row .team-image > div {
        position:relative;
        height:100%;
        overflow:hidden;
    }

    .team-full-row .team-image > div > img {
        transition: opacity 0.5s;
        height:100%;
        width:auto;
        min-width:100%;
        max-width:none;
        margin-left:50%;
        transform: translate(-50%, 0);
    }

    .team-full-row .team-image > div > img:nth-child(2) {
        position:absolute;
        top:0px;
        left:0px;
    }

    .team-full-row .team-image:hover > div > img:nth-child(2) {
        opacity: 0;
    }

    .team-full-row .team-description h4 {
        margin-bottom: 5px;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        font-family: inherit;
    }

    .team-full-row .team-content + .social-medias {
        margin-top:30px;
    }

    .team-full-row .team-description img.signature {
        position: absolute;
        bottom: 1.5em;
        right: 3em;
        width: 200px;
    }

</style>

<script lang="ts">
import { defineComponent, PropType, watch, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore, useCmsModule } from '@fwk-client/utils/vue-3-migration';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';
import { CmsPicture, CmsEnum, CmsLabel, CmsContent } from '@fwk-client/modules/cms/types';
import { SocialMedias } from '../social/types';
import SocialLinks from '../social/SocialLinks.vue';

/** @cmsOptions */
export interface FullRowOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions,
    /** @cmsType CmsPicture */
    picture?:CmsPicture;
    /** @cmsType CmsPicture */
    hoverPicture?:CmsPicture;
    /** @cmsType CmsEnum */
    picturePosition?:CmsEnum<"left"|"right">;
    /** @cmsType CmsInterface */
    socialMedias?:SocialMedias;
    /** @cmsType CmsPicture */
    signature?:CmsPicture;
}

/** @cmsLabels */
export interface FullRowLabels {
    /** @cmsType CmsLabel */
    pictureAlt?:CmsLabel;
    /** @cmsType CmsLabel */
    title?:CmsLabel;
    /** @cmsType CmsLabel */
    position?:CmsLabel;
    /** @cmsType CmsLabel */
    name:CmsLabel;
    /** @cmsType CmsContent */
    text?:CmsContent;
    /** @cmsType CmsLabel */
    signatureAlt?:CmsLabel;
}

/** @cmsSlots */
export interface FullRowSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<FullRowOptions>,
        labels: {
          type: Object as PropType<FullRowLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<FullRowSlots>
    },
    components: {
        Section,
        LocalizedContent,
        SocialLinks
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const cms = useCmsModule();

        
        const computedOptions:FullRowOptions = {
            sectionOptions: {
                section:true, 
                container:false
            },
            picturePosition:"left",
            ...props.options,
            picture : (props.options && props.options.picture) ? cms.utils.getComputedPictureURL(props.options.picture) : undefined,
            hoverPicture : (props.options && props.options.hoverPicture) ? cms.utils.getComputedPictureURL(props.options.hoverPicture) : undefined,
            signature : (props.options && props.options.signature) ? cms.utils.getComputedPictureURL(props.options.signature) : undefined,
        };

        const pictureCssClass = computedOptions.picturePosition == "left" ? "col-lg-4 p-0 team-image" : "col-lg-4 p-0 order-md-last team-image"

        return {
            pictureCssClass,
            computedOptions,
            labels:props.labels
        }

    }
})
</script>